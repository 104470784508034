import React from "react";
import { Layout } from "../components/utilityComponents/layout";
import { graphql, StaticQuery } from "gatsby";
import { DocumentWrapper } from "../components/utilityComponents/documentWrapper";
import { Container } from "../helpers/container";

export default function TermPage() {
  return (
    <StaticQuery
      query={graphql`
        {
          strapiTerm {
            term {
              content
            }
          }
        }
      `}
      
      render={({
        strapiTerm: {
          term: { content },
        },
      }) => {
        return (
          <Layout
            darkBackground
            headerChildren={<h1 className="regulamin__header">POLITYKA PRYWATNOŚCI</h1>}
          >
            <Container>
              <DocumentWrapper content={content} />
            </Container>
          </Layout>
        );
      }}
    />
  );
}
